import { AlertProps, ClickAwayListenerProps, Snackbar } from '@mui/material'
import _ from 'lodash'
import moment from 'moment-timezone'
import {
  ComponentType,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'
import { AGAVE_CONNECTOR_OFFLINE_ERROR_SUBSTRING } from 'siteline-common-all'

const DEFAULT_DURATION = 6000
const DEFAULT_ERROR_DURATION = 12000

type SnackbarContextType = {
  show: (
    message: string,
    severity: AlertProps['color'],
    autoClose: boolean,
    duration?: number
  ) => void
  showLoading: (message?: string, autoClose?: boolean) => void
  showWarning: (message: string, duration?: number) => void
  showError: (error: string, duration?: number) => void
  showErrors: (errors: string[], duration?: number) => void
  recordError: (error: Error, message: string, duration?: number) => void
  showSuccess: (message?: string) => void
  showInfo: (message: string) => void
  showInfos: (message: string[]) => void
  closeAll: () => void
}

const SnackbarContext = createContext<SnackbarContextType>({
  show: (): void => undefined,
  showLoading: (): void => undefined,
  showWarning: (): void => undefined,
  showError: (): void => undefined,
  showErrors: (): void => undefined,
  recordError: (): void => undefined,
  showSuccess: (): void => undefined,
  showInfo: (): void => undefined,
  showInfos: (): void => undefined,
  closeAll: (): void => undefined,
})

export function useSitelineSnackbar() {
  return useContext(SnackbarContext)
}

export type SitelineSnackbarProviderProps = {
  children: ReactNode
  AlertComponent: ComponentType<AlertProps>
}

// Inspired from https://github.com/TeamWertarbyte/material-ui-snackbar-provider
export function SitelineSnackbarProvider({
  children,
  AlertComponent,
}: SitelineSnackbarProviderProps) {
  const [message, setMessage] = useState<string | JSX.Element>('')
  const [open, setOpen] = useState(false)
  const [severity, setSeverity] = useState<AlertProps['color']>('success')
  const [autoClose, setAutoClose] = useState(false)
  const [key, setKey] = useState<string | undefined>()
  const [autoHideDuration, setAutoHideDuration] = useState<number | undefined>()

  const contextValue = useMemo(
    () => ({
      show: (
        message: string | JSX.Element,
        severity: AlertProps['color'],
        autoClose: boolean,
        duration?: number
      ) => {
        setMessage(message)
        setSeverity(severity)
        setAutoClose(autoClose)
        setOpen(true)
        setKey(moment.utc().unix().toString())
        setAutoHideDuration(duration)
      },
      showLoading: (message?: string, autoClose = false) => {
        contextValue.show(message ?? 'Performing action...', 'info', autoClose)
      },
      showWarning: (message: string, duration = DEFAULT_DURATION) => {
        contextValue.show(message, 'warning', true, duration)
      },
      showError: (error: string, duration = DEFAULT_ERROR_DURATION) => {
        // We intercept the Agave Connector error, since it will already be shown by the
        // `AgaveConnectorOfflinePopup` (based on an Apollo link intercepting GraphQL errors
        // in `SitelineApolloProvider`), so we don't need to show it here as well. Simply close any
        // visible snackbars and return.
        if (error.includes(AGAVE_CONNECTOR_OFFLINE_ERROR_SUBSTRING)) {
          contextValue.closeAll()
          return
        }
        contextValue.show(error, 'error', true, duration)
      },
      showErrors: (errors: string[], duration = DEFAULT_ERROR_DURATION) => {
        const uniqueErrors = _.uniq(errors)
        const error = (
          <>
            {uniqueErrors.map((error) => (
              <p key={error}>{error}</p>
            ))}
          </>
        )
        contextValue.show(error, 'error', true, duration)
      },
      recordError: (error: Error, message: string, duration = DEFAULT_ERROR_DURATION) => {
        console.error(error)
        contextValue.show(message, 'error', true, duration)
      },
      showSuccess: (message?: string) => {
        contextValue.show(message ?? 'Done!', 'success', true)
      },
      showInfo: (message: string) => {
        contextValue.show(message, 'info', true)
      },
      showInfos: (messages: string[]) => {
        const uniqueMessages = _.uniq(messages)
        const message = (
          <>
            {uniqueMessages.map((message) => (
              <p key={message}>{message}</p>
            ))}
          </>
        )
        contextValue.show(message, 'info', true)
      },
      closeAll: () => {
        setOpen(false)
      },
    }),
    []
  )

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])

  const jsxMessage = <AlertComponent severity={severity}>{message}</AlertComponent>

  return (
    <>
      <SnackbarContext.Provider value={contextValue}>{children}</SnackbarContext.Provider>
      <SitelineSnackbar
        snackbarKey={key}
        jsxMessage={jsxMessage}
        open={open}
        autoClose={autoClose}
        onClose={handleClose}
        closeOnClickAway={autoClose}
        autoHideDuration={autoHideDuration}
      />
    </>
  )
}

interface SitelineSnackbarProps {
  open: boolean
  onClose: () => void
  jsxMessage: JSX.Element
  autoClose: boolean
  closeOnClickAway: boolean
  snackbarKey?: string
  autoHideDuration?: number
}

export function SitelineSnackbar({
  open,
  onClose,
  jsxMessage,
  autoClose,
  closeOnClickAway,
  snackbarKey,
  autoHideDuration = DEFAULT_DURATION,
}: SitelineSnackbarProps) {
  const ClickAwayListenerProps: Partial<ClickAwayListenerProps> = useMemo(
    () => ({ mouseEvent: closeOnClickAway ? 'onClick' : false }),
    [closeOnClickAway]
  )

  return (
    <Snackbar
      key={snackbarKey}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={open}
      autoHideDuration={autoClose ? autoHideDuration : null}
      onClose={onClose}
      ClickAwayListenerProps={ClickAwayListenerProps}
    >
      {jsxMessage}
    </Snackbar>
  )
}
